import { A, G } from "@mobily/ts-belt";
import moment from "moment-timezone";
import { format } from "date-fns";
import * as constants from "./constants";

export const isCents = (type) => type === constants.PROMO_CODE_TYPES.CENTS;

export const isPercentage = (type) =>
  type === constants.PROMO_CODE_TYPES.PERCENTAGE;

export const keyForType = (type) =>
  isPercentage(type) ? "endAdornment" : "startAdornment";

export const labelForType = (type) =>
  isPercentage(type) ? "Percentage" : "Amount";

export const helperTextForType = (type) =>
  isPercentage(type) ? "Max. 100%" : "Max. $200";

export const textForType = (type) => (isPercentage(type) ? "%" : "$");

export async function createPromoCode(promoCode) {
  const response = await fetch("/api/promoCode", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(promoCode),
  });

  const json = await response.json();

  if (!response.ok) {
    const { errors } = json;
    return Promise.reject(A.head(errors));
  }

  if (json?.promoCode) {
    return json?.promoCode;
  }
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export function discountFor(promoCode) {
  if (isPercentage(promoCode.type)) {
    return `${promoCode.discountPercentage * 100}%`;
  }
  return currencyFormatter.format(promoCode.discountCents / 100);
}

export function discountMaxCentsFor(promoCode) {
  if (
    promoCode.discountMaxCents === 0 ||
    promoCode.type === constants.PROMO_CODE_TYPES.CENTS
  ) {
    return "-";
  }

  return currencyFormatter.format(promoCode.discountMaxCents / 100);
}

export function minPurchaseCentsFor(promoCode) {
  if (promoCode.minPurchaseCents === 0) {
    return "-";
  }

  return currencyFormatter.format(promoCode.minPurchaseCents / 100);
}

export function formatDate(dateStr) {
  return format(new Date(dateStr), "MMM dd, yyyy");
}

export function formatDateWithTimezone(dateStr) {
  const timezone = moment.tz.guess();
  return moment(dateStr).tz("UTC").tz(timezone).format("MMM DD, YYYY");
}

export function maxUsesFor({ maxUses, uses }) {
  const text = G.isNumber(maxUses) ? maxUses : "Unlimited";
  return `${uses} / ${text}`;
}

export function maxUsesPerUserFor({ maxUsesPerUser }) {
  return G.isNumber(maxUsesPerUser) ? maxUsesPerUser : "Unlimited";
}

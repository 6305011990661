import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { Money, CartTotals } from "../../../../domain";

const Total = React.memo(function Total({ totals, purchaseSubscription }) {
  const totalPrice = React.useMemo(() => {
    if (!totals) {
      return Money.ZERO_USD;
    }

    const savedPrice = Money.subtract(
      totals.unadjustedShippingCost,
      totals.shippingCost,
    );

    if (purchaseSubscription && savedPrice.value > 0) {
      return [
        totals.subtotal,
        totals.shippingCost,
        totals.productBump,
        totals.serviceFee,
        totals.tax,
        totals.promoCodeDiscount,
      ].reduce(Money.add);
    }

    return totals.total;
  }, [totals, purchaseSubscription]);

  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <Typography
          variant="h5"
          style={{ fontSize: "1rem", fontWeight: 500 }}
          component="p"
        >
          Total
        </Typography>
      </Grid>

      <Grid
        item
        xs
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item>
          <Typography
            variant="h5"
            style={{ fontSize: "1rem", fontWeight: 500 }}
          >
            USD
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="h5"
            style={{ fontSize: "1rem", fontWeight: 500 }}
          >
            {totalPrice.formattedPrice}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

Total.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
  purchaseSubscription: PropTypes.bool,
};

export default Total;

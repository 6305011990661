import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { DeleteRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { useCart } from "../../../../hooks";
import { PROMO_CODE_TYPES } from "../../../PromoCodeManager/constants";
import { currencyFormatter } from "../../../PromoCodeManager/utils";
import { useCheckout } from "../../CheckoutProvider";
import * as service from "../../service";
import {
  currentStepUpdate,
  FIRST_STEP,
  linkedMessageUpdate,
  purchaseUpdate,
  SECOND_STEP,
} from "../../state";
import * as utils from "../../utils";

const StyledDiscount = styled("span")(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.green.main,
}));

async function removePromoCode() {
  try {
    const response = await window.fetch("/api/promoCode/updateCart", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: null }),
    });

    if (!response.ok) {
      throw new Error("Failed to remove promo code");
    }
  } catch (err) {
    console.error(err);
  }
}

function discountFor(promoCode) {
  if (!promoCode) {
    return null;
  }

  if (promoCode.type === PROMO_CODE_TYPES.CENTS) {
    return `(${currencyFormatter.format(promoCode.discountCents / 100)} off)`;
  }

  if (promoCode.type === PROMO_CODE_TYPES.PERCENTAGE) {
    return `(${promoCode.discountPercentage * 100}% off)`;
  }

  return null;
}

export default function PromoCode({ step }) {
  const { cart, refresh } = useCart();
  const { state, dispatch } = useCheckout();
  const { promoCode } = cart;

  const onClick = async () => {
    if (step === SECOND_STEP) {
      dispatch(currentStepUpdate(FIRST_STEP));
      dispatch(purchaseUpdate(null));
      dispatch(linkedMessageUpdate(""));

      service.cancelPurchase(utils.createCancelPurchaseInfo({ state }));
    }

    await removePromoCode();
    refresh();
  };

  if (!promoCode) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box
        style={{ pointerEvents: "initial" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            Promo Code: <strong>{promoCode?.code}</strong>{" "}
            {discountFor(promoCode)}
          </Typography>

          <IconButton title="Remove Promo Code" onClick={onClick} size="small">
            <DeleteRounded fontSize="small" />
          </IconButton>
        </Box>

        <StyledDiscount>
          {cart.totals.promoCodeDiscount.formattedPrice}
        </StyledDiscount>
      </Box>
    </Grid>
  );
}

PromoCode.propTypes = {
  step: PropTypes.number.isRequired,
};

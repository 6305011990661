import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Money, CartTotals } from "../../../../domain";

const Subtotal = React.memo(function Subtotal({ totals }) {
  const subtotalPrice = React.useMemo(() => {
    if (!totals) {
      return Money.ZERO_USD;
    }

    return [
      totals.subtotal,
      totals.productBump,
      totals.shippingCost,
      totals.promoCodeDiscount,
    ].reduce(Money.add);
  }, [totals]);

  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <StyledTypography>Subtotal</StyledTypography>
      </Grid>

      <Grid
        item
        xs
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item>
          <StyledTypography>USD</StyledTypography>
        </Grid>

        <Grid item>
          <StyledTypography>{subtotalPrice.formattedPrice}</StyledTypography>
        </Grid>
      </Grid>
    </Grid>
  );
});

Subtotal.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  margin: 0,
}));

export default Subtotal;

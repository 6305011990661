import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";

const MessageDialog = React.memo(function MessageDialog({
  buttonProps = {},
  buttonText = "OK",
  confirmButtonProps = {},
  confirmText = "OK",
  maxWidth = "52ch",
  message = "This is an alert message",
  onClose = () => {},
  onConfirm = null,
  open = false,
  title = "Alert",
  type = "error",
}) {
  const openRef = React.useRef(open);
  const [internalOpen, setInternalOpen] = React.useState(open);

  React.useEffect(() => {
    if (open !== openRef.current) {
      openRef.current = open;
      setInternalOpen(open);
    }
  }, [open]);

  return (
    <StyledDialog
      open={internalOpen}
      TransitionProps={{
        onExited: () => {
          if (!internalOpen) {
            onClose();
          }
        },
      }}
      onClose={() => {
        setInternalOpen(false);
      }}
      aria-labelledby="message-dialog-title"
      aria-describedby="message-dialog-description"
    >
      <Typography
        variant="h6"
        gutterBottom
        className={type === "error" ? "error" : ""}
        id="message-dialog-title"
      >
        {title}
      </Typography>

      <Box maxWidth={maxWidth} mt={1} id="message-dialog-description">
        {message}
      </Box>

      <Box display="flex" justifyContent="flex-end" gridGap="8px" mt={1}>
        <Button
          {...buttonProps}
          onClick={() => {
            setInternalOpen(false);
          }}
        >
          {buttonText}
        </Button>

        {onConfirm && (
          <Button color="primary" {...confirmButtonProps} onClick={onConfirm}>
            {confirmText}
          </Button>
        )}
      </Box>
    </StyledDialog>
  );
});

MessageDialog.propTypes = {
  buttonProps: PropTypes.object,
  buttonText: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  confirmText: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(["error", "info"]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3, 4),
    minWidth: "24rem",
    maxWidth: "62ch",
  },
}));

export default MessageDialog;

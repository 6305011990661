import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Money, CartTotals } from "../../../../domain";

const Subtotal = React.memo(function Subtotal({ totals }) {
  const subtotalPrice = React.useMemo(() => {
    if (!totals) {
      return Money.ZERO_USD;
    }

    return [
      totals.subtotal,
      totals.productBump,
      totals.shippingCost,
      totals.promoCodeDiscount,
    ].reduce(Money.add);
  }, [totals]);

  return <Typography variant="h6">{subtotalPrice.formattedPrice}</Typography>;
});

Subtotal.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
};

export default Subtotal;

export const MIN_CODE_LENGTH = 4;
export const MAX_CODE_LENGTH = 80;
export const MAX_DESCRIPTION_LENGTH = 255;
export const MAX_MAX_USES = 1_000_000;
export const MAX_MAX_USES_PER_USER = 1_000;
export const MAX_CENTS_DISCOUNT = 20_000; // $200.00
export const MAX_MIN_PURCHASE_CENTS = 100_000; // $1,000.00
export const MAX_PERCENTAGE_DISCOUNT = 100;

export const PROMO_CODE_TYPES = {
  CENTS: "cents",
  PERCENTAGE: "percentage",
};

export const ENABLE_MAX_USES_PER_USER = false;

import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { CartTotals, Money } from "../../../../domain";

const Total = React.memo(function Total({ totals, purchaseSubscription }) {
  const totalPrice = React.useMemo(() => {
    if (!totals) {
      return Money.ZERO_USD;
    }

    const savedPrice = Money.subtract(
      totals.unadjustedShippingCost,
      totals.shippingCost,
    );

    if (purchaseSubscription && savedPrice.value > 0) {
      return [
        totals.subtotal,
        totals.shippingCost,
        totals.productBump,
        totals.serviceFee,
        totals.tax,
        totals.promoCodeDiscount,
      ].reduce(Money.add);
    }

    return totals.total;
  }, [totals, purchaseSubscription]);

  return <Typography variant="h6">{totalPrice.formattedPrice}</Typography>;
});

Total.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
  purchaseSubscription: PropTypes.bool,
};

export default Total;

import * as React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// components
import Footer from "./components/Footer";
import ScrollProvider from "./services/Scroll";

// pages
import Browse from "./pages/Browse";
import Checkout from "./pages/Checkout";
import SuccessPage from "./pages/Checkout/SuccessPage.jsx";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import MobileViewportHeight from "./services/MobileViewportHeight";
import Navbar from "./components/Navbar";
import Policy from "./pages/Policy";
import ReferralProgram from "./pages/ReferralProgram";
import Shop from "./pages/Shop";
import Testimonials from "./pages/Testimonials";
import Video from "./pages/Video";
import BrowseProducts from "./pages/BrowseProducts";
import { LoadingUI } from "./theme";
import { useCurrentUser } from "./globalState";
import EmailModalProvider from "./services/EmailModal";
import FranchiseHomepage from "./pages/FranchiseHomepage";
import Franchise from "./pages/Franchise";
import WheresMyOrder from "./pages/WheresMyOrder";

// Lazy pages
const AccountDashboard = React.lazy(() => import("./pages/AccountDashboard"));
const TalentDashboard = React.lazy(() => import("./pages/TalentDashboard"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const AccountPayout = React.lazy(() => import("./pages/AccountPayout"));
const AutographsMadeSimple = React.lazy(() =>
  import("./pages/AutographsMadeSimple"),
);
const ProductCharacterAssignment = React.lazy(() =>
  import("./pages/ProductCharacterAssignment"),
);
const CustomerDashboard = React.lazy(() => import("./pages/CustomerDashboard"));
const PendingDashboard = React.lazy(() => import("./pages/PendingDashboard"));
const ManagePurchase = React.lazy(() => import("./pages/ManagePurchase"));
const Snapshot = React.lazy(() => import("./pages/Snapshot"));
const OnboardingForm = React.lazy(() => import("./pages/OnboardingForm/"));
const Pending = React.lazy(() => import("./pages/OnboardingForm/Pending"));

export default function Routes() {
  const location = useLocation();
  const currentUser = useCurrentUser();

  if (location.pathname === "/checkout") {
    return <Checkout />;
  }

  if (location.pathname === "/checkout-success") {
    return <SuccessPage />;
  }

  if (location.pathname === "/pca" && currentUser.isAdmin) {
    return (
      <React.Suspense fallback={<LoadingUI message="Loading..." />}>
        <React.Fragment>
          <Helmet>
            <title>Product Character Assignment | Streamily</title>
            <meta
              name="description"
              content="Product Character Assignment | Streamily"
            />
          </Helmet>
          <ProductCharacterAssignment />
        </React.Fragment>
      </React.Suspense>
    );
  }

  return (
    <ScrollProvider>
      <EmailModalProvider>
        <MobileViewportHeight />

        <Navbar />

        <React.Suspense fallback={<LoadingUI message="Loading..." />}>
          <Switch>
            <Route
              path="/products"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Products</title>
                    <meta name="description" content="Streamily | Products" />
                  </Helmet>

                  <BrowseProducts {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/contact"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Contact</title>
                    <meta name="description" content="Streamily | Contact" />
                  </Helmet>
                  <Contact {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/policy"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Policy</title>
                    <meta name="description" content="Streamily | Policy" />
                  </Helmet>
                  <Policy {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/coa"
              render={(props) => (
                <React.Fragment>
                  <Redirect {...props} to="/wheres-my-order" />
                </React.Fragment>
              )}
            />

            <Route
              path="/talent/signup"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Onboarding Form</title>
                    <meta
                      name="description"
                      content="Streamily | Onboarding Form"
                    />
                  </Helmet>
                  <OnboardingForm {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/talent/pending"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Pending Applications</title>
                    <meta
                      name="description"
                      content="Streamily | Pending Applications"
                    />
                  </Helmet>
                  <Pending {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/testimonials"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Talent Testimonials</title>
                    <meta
                      name="description"
                      content="Streamily | Talent Testimonials"
                    />
                  </Helmet>
                  <Testimonials {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/video"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Video</title>
                    <meta name="description" content="Streamily | Video" />
                  </Helmet>
                  <Video {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/snapshot"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Snapshot</title>
                    <meta name="description" content="Streamily | Snapshot" />
                  </Helmet>
                  <Snapshot {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/wheres-my-order"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Where’s My Order?</title>
                    <meta
                      name="description"
                      content="Streamily | Where’s My Order?"
                    />
                  </Helmet>
                  <WheresMyOrder {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/AutographsMadeSimple"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Autographs Made Simple</title>
                    <meta
                      name="description"
                      content="Streamily | Autographs Made Simple"
                    />
                  </Helmet>
                  <AutographsMadeSimple {...props} />
                </React.Fragment>
              )}
            />

            {currentUser.isAdmin && (
              <Route
                path="/admin/payouts/request"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/payouts/request"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isAdmin && (
              <Route
                path="/admin/payouts"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/payouts"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isAdmin && (
              <Route
                path="/admin/autopay"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/autopay-list"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isAdmin && (
              <Route
                path="/admin/productTypes"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/productTypes"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isAdmin && (
              <Route
                path="/admin/presignedApproval"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/presignedApproval"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isAdmin && (
              <Route
                path="/admin/promoCodeManager"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/promoCodeManager"} />
                  </React.Fragment>
                )}
              ></Route>
            )}

            {currentUser.isAdmin && (
              <Route // TODO - admin auth this route
                path="/admin"
                render={() => (
                  <React.Fragment>
                    <Redirect to={"/account-dashboard/admin"} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isLoggedIn && (
              <Route
                path="/account-dashboard"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Account Dashboard</title>
                      <meta
                        name="description"
                        content="Streamily | Account Dashboard"
                      />
                    </Helmet>
                    <AccountDashboard {...props} />
                  </React.Fragment>
                )}
              />
            )}

            <Route path="/logout" render={(props) => <Logout {...props} />} />

            {currentUser.isLoggedIn && (
              <Route
                path="/account/payout"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Account | Payout</title>
                      <meta
                        name="description"
                        content="Streamily | Account | Payout"
                      />
                    </Helmet>
                    <AccountPayout {...props} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isLoggedIn && (
              <Route
                path="/account/referral"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Account | Referal Program</title>
                      <meta
                        name="description"
                        content="Streamily | Account | Referal Program"
                      />
                    </Helmet>
                    <ReferralProgram {...props} />
                  </React.Fragment>
                )}
              />
            )}

            <Route
              path="/pendingdashboard/:type?/:id?"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Pending Dashboard</title>
                    <meta
                      name="description"
                      content="Streamily | Pending Dashboard"
                    />
                  </Helmet>
                  <PendingDashboard {...props} />
                </React.Fragment>
              )}
            />

            {currentUser.isLoggedIn && (
              <Route
                path="/dashboard/:type?/:id?"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Dashboard</title>
                      <meta
                        name="description"
                        content="Streamily | Dashboard"
                      />
                    </Helmet>
                    <Dashboard {...props} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isLoggedIn && (
              <Route
                path="/account/orders/:orderId"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Order | Details</title>
                      <meta
                        name="description"
                        content="Streamily | Order | Details"
                      />
                    </Helmet>
                    <ManagePurchase {...props} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isLoggedIn && (
              <Route
                path="/account/orders"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Account | Orders</title>
                      <meta
                        name="description"
                        content="Streamily | Account | Orders"
                      />
                    </Helmet>
                    <CustomerDashboard {...props} />
                  </React.Fragment>
                )}
              />
            )}

            {currentUser.isLoggedIn && (
              <Route
                path="/talent-dash"
                render={(props) => (
                  <React.Fragment>
                    <Helmet>
                      <title>Streamily | Talent Dashboard</title>
                      <meta
                        name="description"
                        content="Streamily | Talent Dashboard"
                      />
                    </Helmet>

                    <TalentDashboard {...props} />
                  </React.Fragment>
                )}
              />
            )}

            <Route
              path="/browse"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Browse</title>
                    <meta name="description" content="Streamily | Browse" />
                  </Helmet>
                  <Browse {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/franchiseHomepage"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Franchise Homepage</title>
                    <meta
                      name="description"
                      content="Streamily | Franchise Homepage"
                    />
                  </Helmet>
                  <FranchiseHomepage {...props} />
                </React.Fragment>
              )}
            />

            <Route
              path="/franchise/:route"
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily | Franchise</title>
                    <meta name="description" content="Streamily | Franchise" />
                  </Helmet>
                  <Franchise {...props} />
                </React.Fragment>
              )}
            />

            <Route path="/:route/:productId?" component={Shop} />

            <Route
              render={(props) => (
                <React.Fragment>
                  <Helmet>
                    <title>Streamily - Livestream Autographs</title>
                    <meta
                      name="description"
                      content="Get livestream autographs from all your favorite celebrities signed LIVE on Instagram, YouTube, Twitch, and TikTok delivered straight to your door."
                    />
                    <link rel="canonical" href="https://streamily.com" />
                  </Helmet>
                  <Home {...props} />
                </React.Fragment>
              )}
            />
          </Switch>
        </React.Suspense>

        {location.pathname.toLowerCase() !== "/" && <Footer />}
      </EmailModalProvider>
    </ScrollProvider>
  );
}

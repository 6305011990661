import { Box, Button } from "@material-ui/core";
import { A } from "@mobily/ts-belt";
import * as React from "react";
import { useCart } from "../../../../hooks";
import { Form } from "../../../../theme";

async function updatePromoCodeInCart(code) {
  const response = await window.fetch("/api/promoCode/updateCart", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  });

  if (!response.ok) {
    const { errors } = await response.json();
    const error = A.head(errors);
    throw new Error(error?.message || "Something went wrong");
  }
}

export default function PromoCodeInput() {
  const [promoCode, setPromoCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { cart, refresh } = useCart();

  const applyPromoCode = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      if (isLoading) {
        return;
      }

      try {
        setIsLoading(true);
        await updatePromoCodeInCart(promoCode);
        await refresh();
        setPromoCode("");
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    },
    [isLoading, promoCode, refresh],
  );

  const errorText = React.useMemo(() => {
    if (error) {
      return (
        <span
          onClick={() => {
            setError(null);
          }}
        >
          {error}
        </span>
      );
    }

    return null;
  }, [error]);

  return (
    <Box
      alignItems="flex-start"
      component="form"
      display="flex"
      gridGap="8px"
      mt={2}
      onSubmit={applyPromoCode}
      style={{ pointerEvents: "initial" }}
    >
      <Form.Input
        disabled={isLoading}
        error={Boolean(error)}
        helperText={errorText}
        inputProps={{ minLength: 4, maxLength: 80 }}
        label="Promo Code"
        name="promoCode"
        onChange={(evt) => {
          setError(null);
          setPromoCode(evt.target.value);
        }}
        required
        size="small"
        value={promoCode}
      />

      {promoCode && cart?.promoCode?.code !== promoCode && (
        <Button type="submit" disabled={isLoading}>
          Apply
        </Button>
      )}
    </Box>
  );
}

import { Box, Button, Collapse, Slider, Typography } from "@material-ui/core";
import { D, F } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../domain";
import { useDebounce } from "../../hooks";
import { Form } from "../../theme";
import { currencyFormatter } from "../../util";
import Filter from "./Filter";
import { defaultFilter, MAX_PRICE, useFilters } from "./utils";

const getName = D.get("name");
const getInfluencerId = D.get("influencerId");

const floor = (n) => Math.floor(n / 100) * 100;
const ceil = (n) => Math.ceil(n / 100) * 100;

export default function Filters({ showFilters, matchesSmDown, filterAtom }) {
  const [price, setPrice] = React.useState([0, MAX_PRICE]);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(MAX_PRICE / 100);
  const [filter, setFilter] = useAtom(filterAtom);
  const { filters } = useFilters();

  const setFilterAndRestoreRank = React.useCallback(
    (fn) => setFilter((filter) => D.merge(fn(filter), { rank: 0 })),
    [setFilter],
  );

  React.useEffect(() => {
    setMinPrice(price[0] / 100);
    setMaxPrice(price[1] >= MAX_PRICE ? "200+" : price[1] / 100);
  }, [price]);

  useDebounce(
    () => {
      const minPrice = price[0];
      const maxPrice = price[1] >= MAX_PRICE ? 0 : price[1];

      if (minPrice === filter.minPrice && maxPrice === filter.maxPrice) {
        return;
      }

      setFilterAndRestoreRank(D.merge({ minPrice, maxPrice }));
    },
    250,
    [filter, price],
  );

  return (
    <div>
      <Collapse in={showFilters}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h6">Filters</Typography>

          {!F.equals(filter, defaultFilter) && (
            <Button
              size="small"
              onClick={() => {
                setPrice([0, MAX_PRICE]);
                setFilter(defaultFilter);
              }}
            >
              Clear
            </Button>
          )}
        </Box>

        {Boolean(filters.franchise.length) && (
          <Filter
            matchesSmDown={matchesSmDown}
            options={filters.franchise}
            label="Franchises"
            getOptionLabel={F.identity}
            getOptionValue={F.identity}
            selected={filter.franchise}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(
                  D.update("franchise", D.set(key, value)),
                );
              } else {
                setFilterAndRestoreRank(
                  D.update("franchise", D.deleteKey(key)),
                );
              }
            }}
          />
        )}

        {Boolean(filters.character.length) && (
          <Filter
            matchesSmDown={matchesSmDown}
            options={filters.character}
            label="Characters"
            getOptionLabel={F.identity}
            getOptionValue={F.identity}
            selected={filter.character}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(
                  D.update("character", D.set(key, value)),
                );
              } else {
                setFilterAndRestoreRank(
                  D.update("character", D.deleteKey(key)),
                );
              }
            }}
          />
        )}

        {Boolean(filters.tags.length) && (
          <Filter
            matchesSmDown={matchesSmDown}
            options={filters.tags}
            label="Tags"
            getOptionLabel={F.identity}
            getOptionValue={F.identity}
            selected={filter.tags}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(D.update("tags", D.set(key, value)));
              } else {
                setFilterAndRestoreRank(D.update("tags", D.deleteKey(key)));
              }
            }}
          />
        )}

        {Boolean(filters.talents.length) && (
          <Filter
            expandedByDefault
            matchesSmDown={matchesSmDown}
            options={filters.talents}
            label="Talent"
            getOptionLabel={getName}
            getOptionValue={getInfluencerId}
            selected={filter.talents}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(D.update("talents", D.set(key, value)));
              } else {
                setFilterAndRestoreRank(D.update("talents", D.deleteKey(key)));
              }
            }}
          />
        )}

        <Filter
          expandedByDefault={D.isNotEmpty(filter.signedType)}
          matchesSmDown={matchesSmDown}
          options={SignType.list()}
          label="Signing Types"
          getOptionLabel={SignType.toLabel}
          getOptionValue={F.identity}
          search={false}
          selected={filter.signedType}
          maxHeight={matchesSmDown ? 3.5 : 4.5}
          setSelected={({ value, key }) => {
            if (value) {
              setFilterAndRestoreRank(
                D.update("signedType", D.set(key, value)),
              );
            } else {
              setFilterAndRestoreRank(D.update("signedType", D.deleteKey(key)));
            }
          }}
        />

        {Boolean(filters.type.length) && (
          <Filter
            matchesSmDown={matchesSmDown}
            options={filters.type}
            label="Product Types"
            getOptionLabel={getName}
            getOptionValue={getName}
            selected={filter.type}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(D.update("type", D.set(key, value)));
              } else {
                setFilterAndRestoreRank(D.update("type", D.deleteKey(key)));
              }
            }}
          />
        )}

        <Box my={matchesSmDown ? 2 : 4}>
          <Typography gutterBottom variant="subtitle2" id="price-slider">
            Price
          </Typography>

          <Box mt={1} display="flex" flexDirection="column" gridGap="8px">
            <Box display="flex" justifyContent="space-between" gridGap="8px">
              <Form.Input
                inputProps={{ inputMode: "numeric" }}
                InputProps={{ startAdornment: "$" }}
                label="Min Price"
                size="small"
                type="text"
                value={minPrice}
                variant="outlined"
                onChange={(evt) => {
                  setMinPrice(evt.taget.value);

                  if (!evt.target.value) {
                    return;
                  }

                  const parsedValue = Number(evt.target.value) * 100;

                  if (Number.isNaN(parsedValue)) {
                    return;
                  }

                  setPrice([floor(parsedValue), price[1]]);
                }}
              />

              <Form.Input
                inputProps={{ inputMode: "numeric" }}
                InputProps={{ startAdornment: "$" }}
                label="Max Price"
                size="small"
                type="text"
                value={maxPrice}
                variant="outlined"
                onChange={(evt) => {
                  setMaxPrice(evt.target.value);

                  if (!evt.target.value) {
                    return;
                  }

                  const parsed = evt.target.value.replace(/\D/g, "");
                  const parsedValue = Number(parsed) * 100;

                  if (Number.isNaN(parsedValue)) {
                    return;
                  }

                  setPrice([price[0], ceil(parsedValue)]);
                }}
              />
            </Box>

            <Slider
              aria-labelledby="price-slider"
              getAriaValueText={(value) =>
                currencyFormatter.format(value / 100)
              }
              max={MAX_PRICE}
              min={0}
              step={1}
              valueLabelDisplay="off"
              value={price}
              onChange={(_, newValue) => {
                const [min, max] = newValue;
                setPrice([floor(min), ceil(max)]);
              }}
            />
          </Box>

          {!F.equals(filter, defaultFilter) && (
            <Box mt={matchesSmDown ? 2 : 4}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={() => {
                  setPrice([0, MAX_PRICE]);
                  setFilter(defaultFilter);
                }}
              >
                Clear Filters
              </Button>
            </Box>
          )}
        </Box>
      </Collapse>
    </div>
  );
}

Filters.propTypes = {
  matchesSmDown: PropTypes.bool,
  showFilters: PropTypes.bool,
  filterAtom: PropTypes.object,
};

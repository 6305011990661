import { Box, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Addon, Cart, CartProduct } from "../../../../domain";
import { InfoTooltip } from "../../../../theme";

function retentionTextFor({ name, title }) {
  switch (name) {
    default:
      return `Are you sure you want to remove the ${title} add-on?`;
  }
}

const StyledChip = withStyles((theme) => ({
  label: {
    color: theme.palette.grey[100],
  },
  root: {
    background: theme.palette.grey[800],
    "&:focus, &:focus-within": {
      background: theme.palette.grey[900],
    },
  },
  deleteIcon: {
    color: theme.palette.grey[300],
    "&:hover": {
      color: "white",
    },
  },
}))(Chip);

function AddedAddonChip({
  addon,
  refresh,
  cartProduct,
  setConfirmDialog,
  disabled,
}) {
  const handleDelete = React.useCallback(() => {
    setConfirmDialog({
      title: `Remove Add-on`,
      message: retentionTextFor(addon),
      buttonText: "Keep",
      confirmText: "Remove",
      async onConfirm() {
        try {
          await Cart.removeProductAddon({
            productId: cartProduct.productId,
            addonName: addon.name,
          });
          refresh();
          setConfirmDialog({});
        } catch (err) {
          console.error("Failed to remove addon");
        }
      },
      onClose() {
        setConfirmDialog({});
      },
    });
  }, [addon, cartProduct.productId, refresh, setConfirmDialog]);

  const label = React.useMemo(() => {
    return `${addon.title} (+${addon.price.format()})`;
  }, [addon]);

  return (
    <StyledChip
      label={label}
      onDelete={disabled || addon.price.value === 0 ? undefined : handleDelete}
      size="small"
    />
  );
}

function UnaddedAddonChip({ addon, refresh, cartProduct }) {
  const handleAdd = React.useCallback(async () => {
    await Cart.addProductAddon({
      productId: cartProduct.productId,
      addonName: addon.name,
    });
    refresh();
  }, [addon.name, cartProduct.productId, refresh]);

  return (
    <Box display="flex" gridGap="2px" alignItems="flex-start" key={addon.name}>
      <Chip
        label={`Add ${addon.title} - ${addon.price.format()}`}
        title={addon.description}
        onClick={handleAdd}
        size="small"
      />
      <InfoTooltip
        size="small"
        title={`${addon.title} - ${addon.price.format()}`}
        content={addon.description}
      />
    </Box>
  );
}

AddedAddonChip.propTypes = {
  addon: PropTypes.instanceOf(Addon),
  refresh: PropTypes.func.isRequired,
  cartProduct: PropTypes.instanceOf(CartProduct).isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

UnaddedAddonChip.propTypes = {
  addon: PropTypes.instanceOf(Addon),
  refresh: PropTypes.func.isRequired,
  cartProduct: PropTypes.instanceOf(CartProduct).isRequired,
  disabled: PropTypes.bool,
};

export { AddedAddonChip, UnaddedAddonChip };

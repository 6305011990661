import { Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Addon, CartProduct } from "../../../../domain";
import {
  useAvailableAddonsForProduct,
  useCart,
  useMatchesMdDown,
} from "../../../../hooks";
import { OPT_IN_ADDON_CHECKOUT_ENABLED } from "../../../../util/featureFlags";
import MessageDialog from "../../../MessageModal";
import * as shopUtils from "../../../Shop/utils";
import { useCheckout, useCheckoutErrors } from "../../CheckoutProvider";
import { SECOND_STEP } from "../../state";
import { AddedAddonChip, UnaddedAddonChip } from "./AddonChip";

function priceFor({ addon, cart }) {
  if (Addon.isVipAddon(addon) && cart.purchaseSubscription) {
    return 0;
  }
  return addon.rawPrice;
}

const CartProductElement = React.memo(function CartProductElement({
  cartProduct,
}) {
  const [confirmDialog, setConfirmDialog] = React.useState({});
  const { cart, refresh } = useCart();
  const { state } = useCheckout();
  const { currentStep } = state;
  const { productErrors } = useCheckoutErrors();
  const matchesMdDown = useMatchesMdDown();
  const { availableAddons } = useAvailableAddonsForProduct(
    cartProduct.productId,
  );

  const { addedAddons, unaddedAddons } = React.useMemo(() => {
    if (!availableAddons) {
      return { addedAddons: [], unaddedAddons: [] };
    }

    return D.values(availableAddons).reduce(
      (acc, addon) => {
        const cpAddon = cartProduct.addons.find((a) => a.name === addon.name);
        if (cpAddon) {
          acc.addedAddons.push(
            addon.clone({ addon_price: priceFor({ addon: cpAddon, cart }) }),
          );
        } else if (Addon.isVipAddon(addon) && cart.purchaseSubscription) {
          // Bit of a hack to make sure the VIP addon shows like it is
          // added even when it is not currently added to the cart, but
          // since the user is purchasing a subscription their order will
          // be made VIP anyway
          acc.addedAddons.push(addon.clone({ addon_price: 0 }));
        } else {
          acc.unaddedAddons.push(addon);
        }
        return acc;
      },
      { addedAddons: [], unaddedAddons: [] },
    );
  }, [cart, cartProduct, availableAddons]);

  const specialMessage = React.useMemo(() => {
    if (cartProduct.addons.find(Addon.isVideoMessageAddon)) {
      return (
        <StyledMessage align={matchesMdDown ? "left" : "right"} variant="body2">
          Record your Video Message to {cart.influencer.name} after checkout!
        </StyledMessage>
      );
    }
  }, [cartProduct, cart, matchesMdDown]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs="auto">
        <StyledImage src={cartProduct.imageUrl} alt={cartProduct.name} />
      </Grid>

      <Grid item xs>
        <StyledTitle variant="subtitle2" component="p">
          {cartProduct.name}
        </StyledTitle>

        <StyledTypography component="p">
          {shopUtils.signerTextFor(cartProduct)}
        </StyledTypography>

        <StyledTypography component="p">
          {cartProduct.productType}
        </StyledTypography>

        <StyledTypography component="p">
          <strong>To: </strong>
          {cartProduct.personalizationTo}
        </StyledTypography>

        <StyledTypography component="p">
          <strong>Special instructions: </strong>
          {cartProduct.notes || "None"}
        </StyledTypography>
      </Grid>

      <Grid item>
        <StyledPrice variant="body1">
          {cartProduct.subtotal.format()}
        </StyledPrice>
      </Grid>

      <Grid item sm={12}>
        <Box
          style={{ userSelect: "initial", pointerEvents: "initial" }}
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          gridGap="4px"
        >
          {addedAddons.map((addon) => (
            <AddedAddonChip
              addon={addon}
              cartProduct={cartProduct}
              key={addon.name}
              refresh={refresh}
              setConfirmDialog={setConfirmDialog}
              disabled={currentStep === SECOND_STEP}
            />
          ))}
        </Box>
      </Grid>

      {currentStep !== SECOND_STEP && OPT_IN_ADDON_CHECKOUT_ENABLED && (
        <Grid item sm={12}>
          <Box
            mt={1}
            style={{ userSelect: "initial", pointerEvents: "initial" }}
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
            gridGap="4px"
          >
            {unaddedAddons.map((addon) => (
              <UnaddedAddonChip
                addon={addon}
                cartProduct={cartProduct}
                key={addon.name}
                refresh={refresh}
                setConfirmDialog={setConfirmDialog}
              />
            ))}
          </Box>
        </Grid>
      )}

      {Boolean(specialMessage) && (
        <Grid item sm={12}>
          {specialMessage}
        </Grid>
      )}

      {cartProduct.productId in productErrors && (
        <Grid item container alignItems="center">
          <Typography variant="caption" color="error">
            {productErrors[cartProduct.productId]}
          </Typography>
        </Grid>
      )}

      <MessageDialog {...confirmDialog} open={D.isNotEmpty(confirmDialog)} />
    </Grid>
  );
});

CartProductElement.propTypes = {
  cartProduct: PropTypes.instanceOf(CartProduct),
};

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: "8rem",
  width: "8rem",
  verticalAlign: "middle",
  objectFit: "cover",
}));

const StyledMessage = styled(Typography)(() => ({
  color: "#9E5050",
  fontWeight: 500,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 600,
  color: theme.palette.text.primary,
  margin: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  margin: 0,
  lineHeight: 1.35,
  "& strong": {
    color: theme.palette.text.secondary,
  },
}));

const StyledPrice = styled(Typography)(() => ({
  fontWeight: 500,
}));

export default CartProductElement;

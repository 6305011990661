import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";
import { Money } from "../../../../domain";
import { useCart, useCartProducts, useTimer } from "../../../../hooks";
import { ErrorUI, LoadingUI } from "../../../../theme";
import { getCurrentTimezoneOffset } from "../../../../util/formatDate";
import { formatSecToMinSec } from "../../../../util/formatSecond";
import { useCheckout } from "../../CheckoutProvider";
import { FIRST_STEP, SECOND_STEP } from "../../state";
import * as utils from "../../utils";
import FeesAndTaxes from "./FeesAndTaxes";
import PromoCode from "./PromoCode";
import PromoCodeInput from "./PromoCodeInput";
import ShippingText from "./ShippingText";
import Subtotal from "./Subtotal";
import Total from "./Total";

const divider = (
  <Grid item xs={12}>
    <Box pt={2} pb={2}>
      <Divider />
    </Box>
  </Grid>
);

/*
 * Totals presents a summary of the currentUser's cart, such as the subtotal,
 * total and tax / shipping
 */

const Totals = React.memo(function Totals() {
  const { cart, isLoading, isError } = useCart();
  const { cartProducts } = useCartProducts();
  const { state } = useCheckout();
  const { currentStep, linkedMessage } = state;

  const { time } = useTimer(getTimeFromCreated(cartProducts));

  const { shipmentRequired } = React.useMemo(
    () => utils.shipmentOptionsFor(cart),
    [cart],
  );

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load cart" />;
  }

  const { totals, purchaseSubscription, pickupAtConvention } = cart;

  const savedPrice = Money.subtract(
    totals.unadjustedShippingCost,
    totals.shippingCost,
  );

  return (
    <Grid container spacing={1}>
      {divider}

      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Subtotal
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {Money.add(totals.subtotal, totals.productBump).formattedPrice}
          </Typography>
        </Grid>
      </Grid>

      <PromoCode step={currentStep} />

      {shipmentRequired && (
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Shipping
            </Typography>
          </Grid>

          <Grid item>
            <ShippingText
              shipping={totals.unadjustedShippingCost.value}
              adjustedShipping={totals.shippingCost.value}
            />
          </Grid>
        </Grid>
      )}

      <FeesAndTaxes totals={totals} step={currentStep} />

      {divider}

      {currentStep === FIRST_STEP && <Subtotal totals={totals} />}

      {currentStep === SECOND_STEP && (
        <Total totals={totals} purchaseSubscription={purchaseSubscription} />
      )}

      <Grid item container spacing={1}>
        {shipmentRequired && !pickupAtConvention && savedPrice.value > 0 && (
          <Grid item container justifyContent="space-between">
            <Grid
              alignItems="center"
              container
              item
              justifyContent="flex-end"
              spacing={1}
              xs
            >
              <Grid item>
                <StyledTypography variant="body2">
                  You Saved {savedPrice.formattedPrice} with your membership
                </StyledTypography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {Boolean(time) && !isFinite(cartProducts[0]?.stock) && (
          <Grid
            item
            xs
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Typography
              variant="caption"
              style={{ color: "#B90000", fontWeight: "600" }}
            >
              {`Reserved for ${formatSecToMinSec(time)}`}
            </Typography>
          </Grid>
        )}

        {currentStep === SECOND_STEP && divider}

        {!linkedMessage &&
          purchaseSubscription &&
          currentStep === SECOND_STEP && (
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2">
                  Streamily Family Membership (Monthly)
                </Typography>
              </Grid>
              <Grid item>
                <StyledTypography variant="body2">
                  {totals.subscriptionAmount.formattedPrice}
                </StyledTypography>
              </Grid>
            </Grid>
          )}

        {linkedMessage && currentStep === SECOND_STEP && (
          <Grid item container>
            <Grid item>
              <StyledTypography variant="body2">
                {linkedMessage}
              </StyledTypography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {currentStep === FIRST_STEP && (
        <Grid item xs={12}>
          <PromoCodeInput step={currentStep} />
        </Grid>
      )}
    </Grid>
  );
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.medium,
  color: theme.palette.grey.midDark,
}));

function getTimeFromCreated(cartProducts = []) {
  if (!cartProducts.length) {
    return;
  }

  const [firstCartProduct] = cartProducts;
  const cartCreated = firstCartProduct.created.getTime();
  const timezoneOffset = getCurrentTimezoneOffset().getTime();

  return Math.max(0, Math.floor(600 - (timezoneOffset - cartCreated) / 1000));
}

export default Totals;

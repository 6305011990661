import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useCurrentUser } from "../../../../hooks";
import { Form } from "../../../../theme";
import { useContactInformation } from "../../CheckoutProvider";
import { contactInformationUpdate } from "../../state";
import CreateAccountSection from "../CreateAccountSection";

/*
 * EmailSection collects the user's email
 */
const EmailSection = React.memo(function EmailSection({ dispatch }) {
  const isFirstUpdateRef = React.useRef(true);
  const { email } = useContactInformation();
  const currentUser = useCurrentUser();

  const handleEmailChange = (evt) => {
    dispatch(contactInformationUpdate({ email: evt.target.value }));
  };

  React.useEffect(() => {
    if (email && !currentUser.email) {
      return;
    }

    if (isFirstUpdateRef.current && !email && currentUser.email) {
      dispatch(contactInformationUpdate({ email: currentUser.email }));
    }

    // This sets the user's autofilled email to their user account email only on
    // first render, so the user can clear out the field if they like.
    isFirstUpdateRef.current = false;
  }, [dispatch, email, currentUser.email]);

  const emailError = email.substring(email.length - 4).toLowerCase() === ".con";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledBox>
          <Form.Header>Contact Information</Form.Header>

          <CreateAccountSection dispatch={dispatch} />
        </StyledBox>
      </Grid>

      <Grid item xs={12}>
        <Form.Input
          fullWidth
          required
          label="Email"
          value={email}
          type="email"
          onChange={handleEmailChange}
          error={emailError}
          helperText={
            emailError && "Did you mean .com? Please double check your email"
          }
        />
      </Grid>
    </Grid>
  );
});

EmailSection.propTypes = {
  dispatch: PropTypes.func,
};

const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
}));

export default EmailSection;
